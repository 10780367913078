import React from "react";
import '../App.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-196541292-3');

const
  DXF = "DXF",
  EPS = "EPS",
  PDF = "PDF", 
  SVG = "SVG",
  PNG = "PNG",
  URI = "https://11creativethings.s3.us-west-2.amazonaws.com",
  IMAGE_URI = URI + "/public_downloads",
  DOWNLOAD_URI = URI + "/public_downloads"
;

/* EXAMPLE:
  {
    name: "Spring Flowers",
    format: [PDF, SVG],
    image: IMAGE_URI + "/FREE_11CreativeSVGs_4kSign/promo.png",
    description: "Amazing back drop",
    link_download: DOWNLOAD_URI + "/FREE_11CreativeSVGs_4kSign/FREE_11CreativeSVGs_4kSign.zip",
    link_instagram: "https://www.instagram.com/p/CbKt7T3jDi6/",
  },
*/

const FILES = [
  {
    name: "3K",
    format: [DXF,EPS,PDF,PNG,SVG],
    image: IMAGE_URI + "/FREE_11CreativeSVGs_3kSign/3k_500x500.jpg",
    link_download: DOWNLOAD_URI + "/FREE_11CreativeSVGs_3kSign/FREE_11CreativeSVGs_3kSign.zip",
  },
  {
    name: "4K",
    format: [DXF,EPS,PDF,PNG,SVG],
    image: IMAGE_URI + "/FREE_11CreativeSVGs_4kSign/4k_500x500.jpg",
    link_download: DOWNLOAD_URI + "/FREE_11CreativeSVGs_4kSign/FREE_11CreativeSVGs_4kSign.zip",
  },
  {
    name: "5K",
    format: [DXF,EPS,PDF,PNG,SVG],
    image: IMAGE_URI + "/FREE_11CReativeSVGs_5k/5k_500x500.jpg",
    link_download: DOWNLOAD_URI + "/FREE_11CReativeSVGs_5k/FREE_11CReativeSVGs_5k.zip",
  },
  {
    name: "BABY",
    format: [DXF,EPS,SVG],
    image: IMAGE_URI + "/FREE_11CreativeSVGs_BABY/Photo_500x500.jpg",
    link_download: DOWNLOAD_URI + "/FREE_11CreativeSVGs_BABY/FREE_11CreativeSVGs_BABY.zip",
  },
  {
    name: "MOM",
    format: [SVG],
    image: IMAGE_URI + "/FREE_11CreativeSVGs_MOM/MOM_500x500.jpg",
    link_download: DOWNLOAD_URI + "/FREE_11CreativeSVGs_MOM/11CreativeSVGs_MOM.zip",
  },
];

function Downloads(props) {

	function trackDownload(event) {
		ReactGA.event({
			category: 'Downloads',
			action: 'click',
			label: event.target.href,
			transport: 'beacon'
		});
	}

	function trackOutbound(event) {
		ReactGA.event({
			category: 'Outbound Link',
			action: 'click',
			label: event.target.href,
			transport: 'beacon'
		});
	}

  return (
    <div>
      <h3>Free Digital Downloads</h3>

      <div id="app-download-container">
        <div className="app-downloads"  data-role="navbar">

        {FILES.map((file, idx) => (
          <div className="download-box" key={idx}>
            {file.image ? (<img alt="" src={file.image} />) : ""}
            <div className="details">
              {file.name ? (<p className="name"><strong></strong>{file.name}</p>) : ""}
              {file.format ? (<p className="format"><em>Format:</em> {file.format.join(', ')}</p>) : ""}
              {file.description ? (<p>{file.description}</p>) : ""}
              {file.link_download ? (
                <a className="download" onClick={trackDownload} href={file.link_download}>Download File</a>
              ):""}
              {file.link_instagram ? (
                <a className="social" onClick={trackOutbound} href={file.link_instagram}><i className="bi bi-instagram"></i></a>
              ):""}
            </div>
          </div>
          ))}

        </div>
      </div>
    </div>
  );
}

export default Downloads;
